.buttonText {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.CardTitle {
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.lables {
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.signInButtonAlign {
  margin: -4px 0 0 0;
}

.newMailHome {
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-weight: 700;
  text-align: left;
  color: rgb(255, 255, 255);
  margin: 2px 0 0;
  font-size: 27px;
}

@media screen and (width <= 655px) {
  .newMailHome {
    width: 0;
    overflow: hidden;
  }
}

.logo {
  margin-right: 4px;
  margin-top: -5px;
  height: 40px;
  width: 40px;
}
@media screen and (width <= 655px) {
  .logo {
    margin-top: -4px;
    height: 32px;
    width: 32px;
  }
}

.navbarLinks {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  justify-self: flex-end;
  align-self: center;
  place-items: center;
}

html, body {
  min-height: 100dvh;
}

.App {
  display: grid;
  min-height: 100dvh;
  grid-template-rows: auto 1fr;
}

.navbar {
  display: grid;
  grid-template-columns: auto auto 1fr;
  background: #001542;
  padding: 16px;
  justify-content: center;
  align-content: center;
  place-items: center;
}

.navbar a {
  color: white;
}

.languageSelect {
  color: white;
  cursor: pointer;
}

.languageSelect svg {
  height: 32px;
  width: 32px;
}

.NotFound404 {
  place-self: center;
  display: grid;
  place-items: center;
  grid-gap: 64px;
  padding: 64px;
  text-align: center;
}

.login {
  display: grid;
  place-content: center;
}

.authForm {
  display: grid;
  gap: 16px 16px;
  width: 80vw;
  max-width: 280px;
}

.authFormRow {
  margin-bottom: 0;
  display: grid;
}

.authFormAsideAction {
  text-align: right;
  font-size: 12px;
}

.authForm label {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 80px 1fr;
}

.authForm.resetPasswordForm {
  max-width: 320px;
}

.authForm.resetPasswordForm label {
  grid-template-columns: 140px 1fr;
}

.authForm.requestResetPasswordForm label {
  grid-template-columns: 54px 1fr;
}

.authForm label > span:first-child { /* The actual label text */
  padding-top: 5px;
  font-weight: bold;
}


.authForm button {
  justify-self: stretch;
}

.authForm .fieldError {
  padding-top: 4px;
  color: #990000;
  text-align: left;
}

.authForm .error {
  color: #990000;
  border: 1px solid;
  border-radius: 4px;
  padding: 8px;
  background: #99000022;
  text-align: center;
}
.authForm .error:empty {
  display: none;
}

.passwordStrength {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr;
  justify-content: center;
  font-weight: normal;
  font-size: 12px;
  gap: 4px;
  background: aliceblue;
  padding: 16px;
  border-radius: 16px;
}
@media screen and (width >= 768px) {
  .passwordStrength {
    align-self: stretch;
    margin-bottom: 22px;
  }
}

.passwordStrength > span {
  display: block;
  margin: 0 0 8px;
  color: rgb(0, 21, 66);
}
.passwordStrength[data-strength='0'] > span {
  color: grey;
}

.passwordStrength[data-strength='0'] {
  color: lightgray;
}
.passwordStrength[data-strength='1'] {
  color: darkseagreen;
}
.passwordStrength[data-strength='2'] {
  color: mediumseagreen;
}
.passwordStrength[data-strength='3'] {
  color: seagreen;
}
.passwordStrength[data-strength='4'] {
  color: forestgreen;
}

.passwordStrengthMeter {
  display: grid;
  grid-auto-flow: column;
  margin: 0;
  padding: 0;
  grid-template-columns: repeat(auto-fill, 1fr);
  gap: 4px;
  overflow: hidden;
  white-space: nowrap;
}
.passwordStrengthMeter li {
  border-radius: 3px;
  line-height: 8px;
  border: currentColor solid thin;
  background-color: currentColor;
}
.passwordStrength[data-strength='0'] .passwordStrengthMeter li {
  background-color: transparent;
}
.passwordStrength[data-strength='1'] .passwordStrengthMeter li:nth-child(2),
.passwordStrength[data-strength='1'] .passwordStrengthMeter li:nth-child(3),
.passwordStrength[data-strength='1'] .passwordStrengthMeter li:nth-child(4) {
  background-color: transparent;
}
.passwordStrength[data-strength='2'] .passwordStrengthMeter li:nth-child(3),
.passwordStrength[data-strength='2'] .passwordStrengthMeter li:nth-child(4) {
  background-color: transparent;
}
.passwordStrength[data-strength='3'] .passwordStrengthMeter li:nth-child(4) {
  background-color: transparent;
}

.passwordStrengthInfoStatus {
  text-transform: none;
  padding: 0 0 0 16px;
  align-self: flex-end;
  margin: 0;
}
@media screen and (width <= 655px) {
  .passwordStrengthInfoStatus {
    margin: 16px 0 0;
  }
}

.passwordStrengthInfoStatus li {
  position: relative;
  color: forestgreen;
}
.passwordStrengthInfoStatus li:before {
  position: absolute;
  left: -16px;
}
.passwordStrengthInfoStatus li[data-complete=false] {
  content: '\2713';
  color: rgb(0, 21, 66);
  font-style: italic;
}
.passwordStrengthInfoStatus li[data-complete=true]:before {
  content: '\2713';
}
.passwordStrengthInfoStatus li[data-complete=false]:before {
  content: '\2717';
}

.signupPage {
  display: grid;
  grid-auto-flow: row;
}

.signupPage .OnboardingStepper{
  justify-self: stretch;
  margin: 48px;
}
@media screen and (width <= 655px) {
  .signupPage .OnboardingStepper {
    margin: 16px;
  }
}

.signupForm {
  display: grid;
  justify-self: stretch;
  place-items: center;
}
@media screen and (width <= 655px) {
  .signupForm {
    margin: 0;
    place-self: center;
  }
}

.signupFormInputs {
  justify-self: stretch;
  white-space: nowrap;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 0 auto;
  max-width: 580px;
  grid-gap: 32px;
}
@media screen and (width <= 655px) {
  .signupFormInputs {
    max-width: unset;
    margin: 0;
    grid-template-columns: none;
    grid-gap: 0;
  }
}

.signupForm button{
  min-width: 280px;
}

.userProfile {
  display: flex;
  flex-direction: column;
  justify-self: center;
  gap: 16px;
  margin: 16px;
  max-width: 1440px;
}
@media screen and (width >= 768px) {
  .userProfile {
    gap: 32px;
    margin: 48px;
  }
}

.userProfilePreferences {
  padding: 16px;
}
@media screen and (width >= 768px) {
  .userProfilePreferences {
    padding: 32px;
  }
}

.userProfilePreferences h3 {
  line-height: 24px;
  margin-bottom: 16px;
}

.userProfilePreferences p {
  margin: 8px 0;
  line-height: 24px;
}

.userProfileRoleSelect {
  margin: 32px 0;
  gap: 16px;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(172px, 1fr));
}
@media screen and (width >= 768px) {
  .userProfileRoleSelect {
    margin: 32px;
    gap: 32px;
  }
}
@media screen and (width <= 575px) {
  .userProfileRoleSelect {
    grid-template-columns: auto;
  }
}

.userProfileRoleSelect li {
  color: darkgrey;
  background-color: #FFF;
  transition: color 0.3s;
  border: 1px solid darkgrey;
  cursor: pointer;
  border-radius: 4px;
  padding: 16px;
  display: inline-grid;
  grid-auto-flow: row;
  justify-items: center;
  align-items: center;
  gap: 8px;
}
@media screen and (width <= 575px) {
  .userProfileRoleSelect li {
    grid-auto-flow: column;
    grid-template-columns: auto max-content;
    gap: 16px;
    justify-content: flex-start;
    justify-items: flex-start;
  }
}

.userProfileRoleSelect li:hover, .userProfileRoleSelect li[data-active='true'] {
  color: #FFF;
  background-color: rgb(0, 21, 66);
}

.userProfileRoleSelect li h2 {
  font-size: 14px;
  text-align: center;
}
@media screen and (width <= 575px) {
  .userProfileRoleSelect li h2 {
    display: inline;
    font-size: 16px;
    margin-top: -4px;
    margin-bottom: -4px;
    text-align: left;
  }
}
.userProfileRoleSelect li svg {
  height: 80px;
}
@media screen and (width <= 575px) {
  .userProfileRoleSelect li svg {
    width: 40px;
    height: auto;
  }
}

.userProfilePreferencesForm label {
  width: 360px;
  font-weight: bold;
}
@media screen and (width <= 575px) {
  .userProfilePreferencesForm label {
    width: auto;
  }
}

.userProfilePreferencesForm .ant-select-arrow {
  color: red;
}

.userProfilePreferencesFormItem {
  display: flex;
  flex-direction: column-reverse;
}
.userProfilePreferencesFormItem .examples {
  margin-top: -16px;
  margin-left: 360px;
  margin-bottom: 8px;
  display: inline-flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 8px;
  color: black;
  font-size: 14px;
}
@media screen and (width <= 575px) {
  .userProfilePreferencesFormItem .examples {
    margin-left: 0;
  }
}

.userProfilePreferencesFormItem .examples:empty {
  display: none;
}

.userProfilePreferencesFormItem .examples:before {
  content: 'EXAMPLES:';
  font-size: 10px;
  margin-top: 8px;
}

.userProfilePreferencesFormItem .examples span {
  white-space: nowrap;
  background: #F8BCEF;
  border-radius: 8px;
  padding: 4px 8px;
}

.userProfilePreferencesForm .categoryExamples {
  display: grid;
  gap: 16px;
  margin: 48px 16px 32px;
  position: relative;
  padding-top: 16px;
}

.userProfilePreferencesForm .categoryExamples:empty {
  display: none;
}

.userProfilePreferencesForm .categoryExamples:before {
  position: absolute;
  content: 'EXAMPLES:';
  font-size: 10px;
  margin-top: -16px;
}

.userProfilePreferencesForm .categoryExamples div {
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-columns: 100px minmax(min-content, 1fr);
  gap: 16px;
}

@media screen and (width <= 575px) {
  .userProfilePreferencesForm .categoryExamples div {
    grid-auto-flow: row;
    gap: 8px;
    grid-template-columns: auto;
    place-items: flex-start;
    padding-bottom: 16px;
  }
}

@media screen and (width <= 575px) {
  .userProfilePreferencesForm .categoryExamples div:not(:last-child) {
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  }
}

/* This is a bit of a horror show but I'm rushed. Markup should be changed, fiddly to get responsive - dan w */
.userProfilePreferencesForm .categoryExamples div div {
  display: inline-block;
}

.userProfilePreferencesForm .categoryExamples div div:first-child {
  align-self: flex-start;
  white-space: nowrap;
  background: #FADFFB;
  border-radius: 8px;
  padding: 4px 8px;
}

.userProfilePreferencesForm .categoryExamples div div span {
  display: inline-block;
  background: #F8BCEF;
  border-radius: 8px;
  padding: 4px 8px;
}

/* We're leveraging the Form.Item API for labels and tooltips to render a label without an input
  in order to label a set of child fields. This should probably be a fieldset and legend but time
   constrains us. For now, hide everything except the label. */
.categoryLabelShim > div > div:not(:first-child) {
  display: none;
}

.userProfilePreferencesCategories {
  display: grid;
  grid-auto-flow: row;
  margin: 16px;
  gap: 24px;
}
.userProfilePreferencesCategories__form {
  display: grid;
  align-items: flex-start;
  grid-template-columns: min-content 220px min-content 1fr min-content;
  gap: 16px;
  padding-bottom: 24px;
  border-bottom: solid 1px rgba(5, 5, 5, 0.06);
}
@media screen and (width <= 920px) {
  .userProfilePreferencesCategories__form {
    grid-template-columns: auto;
  }
}

@media screen and (width <= 920px) {
  .userProfilePreferencesCategories__form button {
    justify-self: flex-end;
  }
}

.userProfilePreferencesCategories__form label {
  width: auto;
  white-space: nowrap;
  margin-top: 4px;
}

.webhookContainer {
  background: white;
  display: grid;
  margin: auto;
  position: absolute;
  inset: 0;
  justify-content: center;
  align-items: center;
}

.webhookinfo {
    margin-bottom: 35px;
}

.OnboardingStepper {
  --OnboardingStepper__stepText : ' ';
  --OnboardingStepper__inProgressText : ' ';
  --OnboardingStepper__completedText : ' ';
  border: solid 1px darkgrey;
  border-radius: 16px;
  padding: 32px;
  display: grid;
  place-items: center;
}

.OnboardingStepper__progress {
  max-width: 1120px;
  width: 100%;
  margin: 0 0 32px 60px;
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr max-content;
  align-items: stretch;
  counter-reset: OnboardingStepper__progress;
  position: relative;
}
@media screen and (width <= 655px) {
  .OnboardingStepper__progress {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 48px);
    margin: 0 0 16px;
    justify-content: flex-start;
  }
}

.OnboardingStepper__progress:before {
  position: absolute;
  height: 2px;
  left: 32px;
  right: 96px;
  top: 17px;
  background: green;
  content: ' ';
}
@media screen and (width <= 655px) {
  .OnboardingStepper__progress:before {
    left: 65px;
    right: auto;
    top: 32px;
    bottom: 44px;
    width: 2px;
    height: auto;
  }
}
.OnboardingStepper__progress:after {
  position: absolute;
  height: 2px;
  left: 32px;
  right: 96px;
  top: 17px;
  background: lightgray;
  transform-origin: left;
  content: ' ';
}
@media screen and (width <= 655px) {
  .OnboardingStepper__progress:after {
    left: 65px;
    right: auto;
    top: 32px;
    bottom: 44px;
    width: 2px;
    height: auto;
  }
}

.OnboardingStepper[data-stage="2"] .OnboardingStepper__progress:after {
  transform: translate3d(50%, 0, 0) scaleX(50%);
}
@media screen and (width <= 655px) {
  .OnboardingStepper[data-stage="2"] .OnboardingStepper__progress:after {
    transform: translate3d(0, 50%, 0) scaleY(50%);
  }
}

.OnboardingStepper[data-stage="3"] .OnboardingStepper__progress:after {
  display: none;
}

.OnboardingStepper__progress li {
  position: relative;
  counter-increment: OnboardingStepper__progress;
  font-weight: bold;
}
@media screen and (width <= 655px) {
  .OnboardingStepper__progress li {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 48px 1fr;
    margin-left: 48px;
  }
}

.OnboardingStepper__progress li:before {
  position: absolute;
  top: 46px;
  font-size: 8pt;
  white-space: nowrap;
  text-transform: uppercase;
  content: var(--OnboardingStepper__stepText)" "counter(OnboardingStepper__progress);
  color: darkgrey;
}
@media screen and (width <= 655px) {
  .OnboardingStepper__progress li:before {
    top: 10px;
    left: -48px;
  }
}

.OnboardingStepper__progress li:after {
  display: none;
  position: absolute;
  bottom: -24px;
  font-size: 8pt;
  white-space: nowrap;
  text-transform: uppercase;
  content: var(--OnboardingStepper__completedText);
  color: green;
}
@media screen and (width <= 655px) {
  .OnboardingStepper__progress li:after {
    top: 10px;
    right: 0;
  }
}

.OnboardingStepper__progress li div ~ div {
  width: 100px;
  text-align: center;
  display: inline-block;
  transform: translate3d(-32px, -10px, 0);
}
@media screen and (width <= 655px) {
  .OnboardingStepper__progress li div ~ div {
    transform: translate3d(0, 0, 0);
    padding-top: 4px;
    text-align: left;
  }
}
.OnboardingStepper__progress li div ~ div:before {
  display: none;
}

.OnboardingStepper__progress__iconInProgress {
  display: none;
}
.OnboardingStepper__progress li[data-active=true] .OnboardingStepper__progress__iconComplete, .OnboardingStepper__progress li[data-active=true] ~ li .OnboardingStepper__progress__iconComplete {
  display: none;
}

.OnboardingStepper__progress li[data-active=true] .OnboardingStepper__progress__iconInProgress, .OnboardingStepper__progress li[data-active=true] ~ li .OnboardingStepper__progress__iconInProgress {
  display: block;
  position: relative;
  z-index: 1;
}

.OnboardingStepper__progress li[data-active=true]:after {
  color: blue;
  content: var(--OnboardingStepper__inProgressText);
}

.OnboardingStepper__progress li[data-active=true] ~ li .OnboardingStepper__progress__iconInProgress {
  color: darkgrey;
}
.OnboardingStepper__progress li[data-active=true] ~ li:after {
  color: darkgrey;
  content: ' '
}


.OnboardingStepper__progress li[data-active=true] div:before, .OnboardingStepper__progress li[data-active=true] ~ li div:before, .OnboardingStepper__progress li:last-child div:before {
  display: none;
  position: absolute;
  height: 2px;
  top: 17px;
  background: white;
  content: ' ';
  width: 100%;
}
@media screen and (width <= 655px) {
  .OnboardingStepper__progress li[data-active=true] div:before, .OnboardingStepper__progress li[data-active=true] ~ li div:before, .OnboardingStepper__progress li:last-child div:before {
    left: 15px;
    width: 4px;
    height: 100%;
  }
}

.OnboardingStepper__progress li .OnboardingStepper__progress__iconComplete {
  border: 4px solid white;
  border-radius: 32px;
  color: green;
  margin-bottom: 36px;
  width: 36px;
  display: block;
  position: relative;
  z-index: 1;
}

.OnboardingStepper__progress li .OnboardingStepper__progress__iconInProgress {
  /*border: 4px solid white;*/
  border-radius: 32px;
  color: blue;
  margin-bottom: 36px;
  width: 36px;
}

.OnboardingStepper__activeStage {
  max-width: 1024px;
  width: 100%;
  padding: 16px;
}
@media screen and (width <= 655px) {
  .OnboardingStepper__activeStage {
    padding: 16px 0;
  }
}

.OnboardingStepper__activeStage:empty{
  display: none;
}

.OnboardingStepper__activeStage:not(:first-child){
  border-top: solid thin lightgray;
}

.OnboardingStepper__activeStage h1 {
  color: #001542;
  font-size: 32px;
  line-height: 40px;
  padding: 12px 0;
}
@media screen and (width <= 655px) {
  .OnboardingStepper__activeStage h1 {
    font-size: 24px;
    line-height: 32px;
    padding: 8px 0;
  }
}

.OnboardingStepper__activeStage ol {
  padding: 0 32px;
}
@media screen and (width <= 655px) {
  .OnboardingStepper__activeStage ol {
    padding: 0 0 0 16px;
  }
}
.OnboardingStepper__activeStage li {
  list-style: disc;
  margin-left: 0;
  padding-left: 0;
  line-height: 24px;
  margin-bottom: 4px;
}
@media screen and (width <= 655px) {
  .OnboardingStepper__activeStage li {
    margin-bottom: 8px;
  }
}

.OnboardingStepper__video {
  justify-self: center;
  width: 100%;
  max-width: 840px;
  aspect-ratio: 1920/1200;
}
/* Something weird happens with padding when displaying at small size, adjust */
@media screen and (width <= 655px) {
  .OnboardingStepper__video {
    margin-bottom: -20px;
  }
}

.OnboardingStepper__video iframe {
  width: 100%;
  height: 100%;
}
/* Something weird happens with padding when displaying at small size, adjust */
@media screen and (width <= 655px) {
  .OnboardingStepper__video iframe {
    margin-top: -20px;
  }
}

/* Message Popup Start */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalHeader h2 {
  margin: 0;
}

.closeIcon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.modalContent {
  margin-top: 10px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modalFooter button {
  padding: 8px 20px;
  background-color: #007bff; /* Blue color, change as needed */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modalFooter button:hover {
  background-color: #0056b3; /* Darker blue color on hover */
}

/* Message Popup End */

/* antd overrides - haven't found the "correct" way to do it yet, so using className */

.functionalPreferences {
  display: inline-grid;
  grid-gap: 16px;
  margin-bottom: 32px;
}
@media screen and (width <= 655px) {
  .functionalPreferences {
    display: grid;
  }
}

.functionalPreferences > * {
  min-height: 32px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}
@media screen and (width <= 655px) {
  .functionalPreferences > * {
    flex-direction: column;
  }
}

.LabelledSwitch {
  display: inline-grid;
  align-items: center;
  grid-template-columns: 226px 60px;
  grid-gap: 16px;
}
.LabelledSwitch__disabled {
  opacity: 0.3;
}
@media screen and (width <= 655px) {
  .LabelledSwitch {
    display: grid;
    grid-template-columns: 1fr 60px;
  }
}
.Switch {
  background: rgba(0, 0, 0, 0.45);
}

@media screen and (width <= 655px) {
  .TimezoneSelect {
    max-width: 278px;
  }
}

.saveChanges {
/*  position: fixed;*/
/*  bottom: 32px;*/
/*  right: 32px;*/
}